import axios from 'axios';

const service = axios.create({
    baseURL: "",
    timeout: 6000
});

service.interceptors.request.use(
    config => {
        return config;
    }
);

service.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        return error;
    }
)

export default service;